
import { useEffect, useState } from 'react';

function ShippingRule({selectedPlace, setSelectedPlace, shippingRule}) {
    const [isPlaceOpen, setIsPlaceOpen] = useState(false);

    const handlePcaeClick = (onePlace) => {
        setSelectedPlace({
            name: onePlace?.name,
            value: onePlace?.name?.toLowerCase()?.replace(/\s/g, '_'),
            id: onePlace?.id,
            shippingCharge: onePlace?.price,
            shippingZones: onePlace?.shipping_zones,
        });
        setIsPlaceOpen(false);
    };

    useEffect(() => {
        function handleOutsideClick(e) {
            if (isPlaceOpen) {
                const placeElement = document.getElementById('selector-Place');

                if (placeElement && !placeElement.contains(e.target)) {
                    setIsPlaceOpen(false);
                }
            }
        }

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isPlaceOpen]);


    return (

        <div className="dropdown cursor-pointer text-sm">
            <div id='selector-Place' className="selector-box" onClick={() => { setIsPlaceOpen(!isPlaceOpen); }}>
                {selectedPlace?.name || 'Select City'}

                {isPlaceOpen ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{width: '0.75rem', height: '1.5rem'}}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" style={{width: '0.75rem', height: '1.5rem'}}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                }

            </div>
            {isPlaceOpen && (
                <div className='position-relative'>
                    <div className='arrow-up-indiactor absolute top-0 left-2'></div>
                    <ul className='selector-options'>
                        {shippingRule?.map((rule, index) => (
                            <li
                                key={index}
                                className={'sortListItem ' + (selectedPlace?.name == rule.name ? 'active' : '')}
                                onClick={() => handlePcaeClick(rule)}
                            >
                                <h6 className='px-3 py-1'>{rule.name}</h6>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default ShippingRule;
