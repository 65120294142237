import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from 'react-select';
import ShippingRule from "./ShippingRule";

const AddCustomerModal = (props) => {
    const navigate = useNavigate();
    const [addCustomerDisable, setAddCustomerDisable] = useState(false);
    // const [selectedShippingRule, setSelectedShippingRule] = useState();
    const [selectedArea, setSelectedArea] = useState(null);
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");
    const [address, setAddress] = useState("");
    const [errorList, setError] = useState();

    const addCustomer = (event) => {
        setAddCustomerDisable(true);
        var obj = {
            customer_name: username,
            customer_contact: contact,
            customer_email: email,
            address: address,
            shipping_id: props?.selectedShippingRule?.id,
            area: selectedArea?.value,
            area_id: selectedArea?.id
        };
        // console.log(props.customer_id);
        // console.log(obj);
        axios
            .post("pos/register-customer", obj)
            .then(function (resp) {
                setAddCustomerDisable(false);
                // console.log(resp.data);
                var data = resp.data;
                console.log(data);
                if (data.success === false) {

                    setError(data.message);
                }
                else if (data.message) {
                    Swal.fire({
                        customClass: {
                            icon: 'mt-4'
                        },
                        position: 'center',
                        icon: 'success',
                        title: data.message,
                        showConfirmButton: true,
                    });
            //    console.log(data?.data?.customer[1]);
                    props?.setCustomer(data?.data?.customer[0]);
                    props?.setCustomerAddressId(data?.data?.customer[1]?.id);
                    props?.setSelectedAddress(data?.data?.customer[1]);
                    props?.setArea(selectedArea?.value);
                    setError(null);
                    props?.setAddCustomerModal(false);
                }
                if (resp?.data?.message === "Unauthorized") {
                    localStorage.removeItem("posUser");
                    navigate({ pathname: '/login', search: '?q=You Need To Login First', replace: true });
                }
            })
            .catch((err) => {
                setAddCustomerDisable(false);
                console.log(err);
                setError(err?.response?.data?.errors)
            });

        event.preventDefault();
    };

    // const [shippingZones, setShippingZones] = useState();
    useEffect(()=>{
        props?.setShippingZones(props?.selectedShippingRule?.shippingZones?.map(item => ({
            value: item.area_name,
            label: item.area_name,
            id: item.id
        })))
    },[props?.selectedShippingRule])
//   console.log(selectedArea);
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Customer
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ps-4 pe-4">
                        <form onSubmit={(e) => { addCustomer(e); }}>
                            <div className="form-group mb-2">
                                <label htmlFor="name" className="required">
                                    Full Name:
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter your full name"
                                    className="form-control square"
                                />
                                {errorList && (<span className='text-danger'>{errorList.customer_name && errorList.customer_name[0]}</span>)}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="email">Email:</label>
                                <input id="email" type="email" name="email" value={email}
                                    onChange={(e) => setEmail(e.target.value)} placeholder="your-email@domain.com" className="form-control square" />
                                {errorList && (<span className='text-danger'>{errorList.customer_email && errorList.customer_email[0]}</span>)}
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="phone" className="required">
                                    Phone:
                                </label>
                                <input id="phone" type="text" name="phone" placeholder="Enter Customer Phone Number" value={contact} onChange={(e) => setContact(e.target.value)} className="form-control square" />
                                {errorList && (<span className='text-danger'>{errorList.customer_contact && errorList.customer_contact[0]}</span>)}
                            </div>

                            <div className="form-group mb-2">
                                <label htmlFor="city" className="required">
                                    City:
                                </label>{" "}
                                <ShippingRule
                                    selectedPlace={props?.selectedShippingRule}
                                    setSelectedPlace={props?.setSelectedShippingRule}
                                    shippingRule={props?.shippingRule}
                                />
                                {errorList && (<span className='text-danger'>{errorList['shipping_id'] && errorList['shipping_id'][0]}</span>)}
                            </div>{" "}

                            <div className="form-group mb-3">
                                <label htmlFor="area" className="required">
                                    Area:
                                </label>
                                <Select id="area"
                                    isClearable={true}
                                    defaultValue={selectedArea}
                                    onChange={setSelectedArea}
                                    options={props?.shippingZones}
                                />
                                 {errorList && (
                                      <span className="text-danger">
                                        {errorList['area_id'] && errorList['area_id'][0]}
                                      </span>
                                    )}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="address" className="required">
                                    Address:
                                </label>
                                <input
                                    id="address"
                                    type="text"
                                    name="address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    // required="required"
                                    placeholder="Enter your address"
                                    className="form-control square"
                                />
                                {errorList && (<span className='text-danger'>{errorList.address && errorList.address[0]}</span>)}
                            </div>

                            <div className="col-md-12">
                                <button type="submit" className="btn customer_add_btn" disabled={addCustomerDisable}>
                                    Register
                                </button>&nbsp;
                                <button type="reset" className="btn btn-light" onClick={props.onHide}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddCustomerModal;
